<i18n>
ru:
  title: "Сайт временно не работает!"
  details: "Производятся технические работы. Спасибо за понимание."
  comeBackSoon: "Совсем скоро мы будем снова с Вами!"
us:
  title: "Website is temporarily unavailable!"
  details: "Technical work is being carried out. Thank you for your understanding."
  comeBackSoon: "We will be back with you soon!"
ua:
  title: "Сайт тимчасово недоступний!"
  details: "Виконуються технічні роботи. Дякуємо за розуміння."
  comeBackSoon: "Ми скоро знову будемо з вами!"
</i18n>

<template>
  <div class="v-off-page-container v-d-flex v-flex-column v-justify-content-center v-align-items-center">
    <arora-nuxt-image
      disable-lazy
      :alt="translate('off.title')"
      :height="250"
      :image="appConfig.VueSettingsPreRun.WebsiteDisabledImage"
      :width="250"
    />
    <span
      class="v-off-page-container--title"
      v-html="translate('off.title')"
    />
    <span v-html="translate('off.details')" />
    <span v-html="translate('off.comeBackSoon')" />
  </div>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
</script>

<style lang="scss">
@use 'assets/variables';

.v-off-page-container {
  height: 100vh;
  gap: 20px;
  background: variables.$BodyBackground;
  &--title {
    font-size: 2em;
  }
}
</style>
